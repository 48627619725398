function toggleSidMenu() {
    // $(window).scroll(() => {
  //     var windscroll = $(window).scrollTop();
    //     if (windscroll >= 100) {
    //       $('.intro_dtls').each((i) => {
    //         // The number at the end of the next line is how pany pixels you from the top you want it to activate.
    //         if ($(this).position().top <= windscroll - 0) {
    //           $('.day_nav li.active').removeClass('active');
    //           $('.day_nav li').eq(i).addClass('active');
    //         }
    //       });
  
    //     } else {
  
    //       $('.day_nav li.active').removeClass('active');
    //       $('.day_nav li:first').addClass('active');
    //     }
  
    //   }).scroll();
 
    
// (function($, window, document){

// 			$('[data-toggle]').on('click', function(event) {
// 				event.preventDefault();
// 				var target = $(this.hash);
// 				target.toggle();
// 			});

// 			// Cache selectors
// 			var lastId,
// 				topMenu = $("#DaysNav"),
// 				topMenuHeight = topMenu.outerHeight() + 15,
// 				// All list items
// 				menuItems = topMenu.find("a"),
// 				// Anchors corresponding to menu items
// 				scrollItems = menuItems.map(function() {
// 					var item = $(this).attr("href");
// 					if(item != '#') {return $(item)}
// 				});

// 			console.log(scrollItems)


// 			// Bind click handler to menu items
// 			// so we can get a fancy scroll animation
// 			menuItems.click(function(e) {
// 				var href = $(this).attr("href"),
// 					offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
// 				$('html, body').stop().animate({
// 					scrollTop: offsetTop
// 				}, 300);
// 				e.preventDefault();
// 			});

// 			// Bind to scroll
// 			$(window).scroll(function() {
// 				// Get container scroll position
// 				var fromTop = $(this).scrollTop() + topMenuHeight;

// 				// Get id of current scroll item
// 				var cur = scrollItems.map(function() {
// 					if ($(this).offset().top < fromTop)
// 						// console.log(this)
// 						return this;
// 				});
// 				// Get the id of the current element
// 				cur = cur[cur.length - 1];
// 				var id = cur && cur.length ? cur[0].id : "";

// 				if (lastId !== id) {
// 					lastId = id;
// 					// Set/remove active class
// 					menuItems
// 						.parent().removeClass("active")
// 						.end().filter("[href='#" + id + "']").parent().addClass("active");
// 				}
// 			});
// 		})(jQuery, window, document);

}